
const $ = require('jquery');
import 'paper';
import * as paper from 'paper';
// called once, when window is ready:
$(document).ready(function () {

        let animation_duration = 10.0; // in sec until all elements are put on canvas
        let time_to_button = 0.5; // sec until start button shows

        let call_to_action = document.getElementById('call_to_action');
        call_to_action.style.visibility = 'hidden';

        var canvas = document.getElementById('splash_canvas');
        paper.setup(canvas);

        var svg_inline = document.getElementById('inline_svg');
        // svg_inline.style.display = 'none'

        let drawingLayer = new paper.Layer();
        drawingLayer.activate();
        let target_drawing = new paper.Group();


        let noshowLayer = new paper.Layer();
        noshowLayer.visible = false;

        const margin = 0;
        const max_steps = 75;
        const min_steps = 50;


        let drawing_reference = readSVG();

        /*
                paper.view.onMouseMove = function (event) {
                    call_to_action.style.display = 'block';
                    paper.view.onMouseMove = null;
                }*/

        paper.view.onClick = function (event) {
            call_to_action.style.visibility = 'visible';
            paper.view.onClick = null;
        }

        paper.view.onKeyDown = function (event) {
            call_to_action.style.visibility = 'visible';
            paper.onKeyDown = null;
        }

        function readSVG() {
            noshowLayer.activate();


            let svg_input = paper.project.importSVG(svg_inline, {expandShapes: true, applyMatrix: true});
            // svg_input.opacity = 0.1;

            let drawing_reference = {
                source_drawing: svg_input,
                duration_per_idx: animation_duration / svg_input.children.length,
                animation_started: false,
            }
            let scale = getScale(drawing_reference.source_drawing, paper.view.bounds.width, paper.view.bounds.height);
            adjust(drawing_reference.source_drawing, scale);
            drawingLayer.activate();
            return drawing_reference;
        }

        function adjust(item, scale_factor) {
            item.scale(scale_factor);
            item.position = paper.view.center;
        }

        function getScale(item, width, height) {
            let scale_factor_x = (width - 2 * margin) / item.bounds.width;
            let scale_factor_y = (height - 2 * margin) / item.bounds.height;
            return Math.min(scale_factor_x, scale_factor_y);
        }

        function onResize(new_width, new_height) {
            let scale = getScale(target_drawing, new_width, new_height)
            adjust(target_drawing, scale)
            paper.view.draw();
        }

        paper.view.onResize = function (event) {
            onResize(event.size.width, event.size.height);
        };


        function randomInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        function randomStart() {
            const direction = randomInteger(0, 3);
            let point;
            switch (direction) {
                case 0:
                    point = new paper.Point(randomInteger(0, paper.view.bounds.width), 0);
                    break;
                case 1:
                    point = new paper.Point(paper.view.bounds.width, randomInteger(0, paper.view.bounds.height));
                    break;
                case 2:
                    point = new paper.Point(randomInteger(0, paper.view.bounds.width), paper.view.bounds.height);
                    break;
                case 3:
                    point = new paper.Point(0, randomInteger(0, paper.view.bounds.height));
                    break;
            }
            return point;
        }

        function getStep(startpos, stoppos, nr_of_steps) {
            let delta = stoppos.subtract(startpos);
            delta = delta.divide(nr_of_steps);
            return delta;
        }

        paper.view.onFrame = function (event) {
            if (!drawing_reference.animation_started) {
                drawing_reference.source_drawing.children.forEach(function (item, index) {
                    let target_item = item.clone();
                    target_item.position = randomStart();
                    let nr_of_steps = randomInteger(min_steps, max_steps);
                    target_item["step"] = getStep(target_item.position, item.position, nr_of_steps);
                    target_item["nr_of_steps"] = nr_of_steps;
                    target_item["actual_step"] = 0;
                    target_item["target_position"] = item.position;
                    target_drawing.addChild(target_item);
                    target_item.onFrame = function (item_event) {
                        if (this.actual_step < this.nr_of_steps) {
                            this.position = this.position.add(this.step);
                            this.actual_step = this.actual_step + 1;
                        } else {
                            this.position = this.target_position;
                            this.onFrame = null;
                        }
                    }
                });
                drawing_reference.animation_started = true;
            }

            if (event.time >= time_to_button) {
                call_to_action.style.visibility = 'visible';
                // paper.view.onClick = null;
                // paper.onKeyDown = null;
            }


        }

        paper.view.draw();


    }
)
